var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{attrs:{"row":"","wrap":"","justify-end":""}},[_c('v-flex',{staticClass:"text-right",attrs:{"xs12":"","sm12":"","md4":"","lg4":""}},[_c('v-text-field',{attrs:{"dense":"","solo":"","placeholder":_vm.$t('search owner')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"no-data-text":_vm.$t('no client available'),"items":_vm.filterOwners,"items-per-page":20},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"35"}},[_c('v-img',{attrs:{"src":item.avatar},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-progress-circular',{attrs:{"width":"3","color":_vm.$store.state.primaryColor,"indeterminate":""}})],1)]},proxy:true}],null,true)})],1)]}},{key:"item.names",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFullnames(item.names))+" ")]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[(item.gender == 'M')?_c('v-icon',{attrs:{"color":"primary darken-3"}},[_vm._v("fas fa-male")]):_c('v-icon',{attrs:{"color":"pink darken-3"}},[_vm._v("fas fa-female")])],1)]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_c('span',{},[_vm._v(_vm._s(_vm.DateFormat(item.dateCreated)))])]}},{key:"item.accessRight",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"color":_vm.$store.state.secondaryColor},on:{"change":function($event){return _vm.accessRight(item)}},model:{value:(item.accessRight),callback:function ($$v) {_vm.$set(item, "accessRight", $$v)},expression:"item.accessRight"}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-icon',{attrs:{"color":"success darken-3"}},[_vm._v("fas fa-check-circle")]):_c('v-icon',{attrs:{"color":"error darken-3"}},[_vm._v("fas fa-times-circle")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return _vm._l((_vm.icons),function(button){return _c('v-icon',{key:button.icon,staticClass:"mx-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","color":button.color},on:{"click":function($event){return _vm.goTo(button.route, item)}}},[_vm._v(_vm._s(button.icon))])})}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t("delete client"))+" ")]),_c('v-card-text',{staticClass:"pa-4"},[_vm._v(" "+_vm._s(_vm.$t("are you sure you want to delete client"))+" "),_c('span',{staticClass:"light-blue--text"},[_vm._v(_vm._s(_vm.client.name + ": " + _vm.client.id)+" ")]),_vm._v(" "+_vm._s(_vm.$t("from the system"))+" ? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":"","loading":_vm.loadingDelete},on:{"click":function($event){return _vm.deleteOwner()}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1)],1)],1),_c('v-snackbar',{attrs:{"dark":"","top":"","center":"","timeout":"4000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attr = ref.attr;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":_vm.$store.state.secondaryColor},on:{"click":function($event){_vm.snackSuccess = false}}},'v-btn',attr,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackSuccess),callback:function ($$v) {_vm.snackSuccess=$$v},expression:"snackSuccess"}},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.message)))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }